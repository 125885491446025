<template>
    <div>
        <a-row type="flex" :gutter="[2,2]">
            <a-col flex="1 0 70%">
                <a-divider orientation="left">
                    Editar Informacion de la Empresa
                </a-divider>

                <div class="profile-container">
                    <div class="profile-image">
                    <img 
                        loading="lazy" 
                        v-if="!empresa.imagen" 
                        class="profile-img" 
                        :src="src" 
                        alt="Mi empresa"
                    >
                    <img 
                        loading="lazy" 
                        v-else 
                        class="profile-img" 
                        :src="empresa.imagen" 
                        alt="Mi empresa"
                    >
                    </div>
                    
                    <h3 class="profile-username">{{ empresa.nombre }}</h3>
                    
                    <p class="profile-owner">{{ empresa.propietario }}</p>

                    <div class="profile-logo">
                    <img 
                        loading="lazy" 
                        v-if="!empresa.logo_horizontal" 
                        class="profile-logo-img" 
                        :src="srcHorizontal" 
                        alt="Mi empresa"
                    >
                    <img 
                        loading="lazy" 
                        v-else 
                        class="profile-logo-img" 
                        :src="empresa.logo_horizontal" 
                        alt="Mi empresa"
                    >
                    </div>

                    <ul class="profile-info">
                    <li><b>DIRECCIÓN :</b> <span class="info-value">{{ empresa.direccion }}</span></li>
                    <li><b>TELEFONO :</b> <span class="info-value">{{ empresa.telefono }}</span></li>
                    <li><b>CELULAR :</b> <span class="info-value">{{ empresa.celular }}</span></li>
                    <li><b>CORREO ELECTRONICO :</b> <span class="info-value">{{ empresa.correo }}</span></li>
                    <li><b>HORARIO DE ATENCION :</b> <span class="info-value">{{ empresa.horario }}</span></li>
                    <li><b>CIUDAD :</b> <span class="info-value">{{ empresa.ciudad }}</span></li>
                    <li><b>LINK WEB :</b> <span class="info-value">{{ empresa.link_web }}</span></li>
                    <li><b>LINK FACEBOOK :</b> <span class="info-value">{{ empresa.link_facebook }}</span></li>
                    <li><b>LINK INSTAGRAM :</b> <span class="info-value">{{ empresa.link_instagram }}</span></li>
                    <li><b>SLOGAN :</b> <span class="info-value">{{ empresa.slogan }}</span></li>
                    <li><b>DESCRIPCION :</b> <span class="info-value">{{ empresa.descripcion }}</span></li>
                    </ul>
                </div>
            </a-col>
            <a-col flex="1 0 30%">
                <a-divider orientation="left">
                    Editar Informacion Empresa
                </a-divider>

                <a-button type="primary" size="large" block @click="openModalEmpresa(null, 'editar')"> 
                    <a-icon type="shop" :style="{ fontSize: '20px', fontWeight: '700' }"/> EDITAR EMPRESA
                </a-button>

                <a-divider orientation="left">
                    Formatear Sistema
                </a-divider>

                <a-button size="large" block @click="abrirFormatearSistema()"> 
                    <a-icon type="shop" :style="{ fontSize: '20px', fontWeight: '700' }"/> FORMATEAR
                </a-button>

                <a-divider orientation="left">
                    Exportar Backup
                </a-divider>
                <a-button type="dashed" size="large" block> 
                    <a-icon type="shop" :style="{ fontSize: '20px', fontWeight: '700' }"/> EXPORTAR
                </a-button>
                <a-divider orientation="left">
                    Importar Backup
                </a-divider>
                <a-button type="dashed" size="large" block> 
                    <a-icon type="shop" :style="{ fontSize: '20px', fontWeight: '700' }"/> IMPORTAR
                </a-button>
                <a-divider orientation="left">
                    Logs de Sistema
                </a-divider>
                <a-button type="dashed" size="large" block> 
                    <a-icon type="shop" :style="{ fontSize: '20px', fontWeight: '700' }"/> LOGS
                </a-button>
            </a-col>
        </a-row>
    
        <!--EMPRESA-->
        <a-modal
            v-model="modalEmpresa"
            title="GESTION DE EMPRESA"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1051"
            >
    
            <div class="row mb-12">
                <div class="row">
                    <div class="col-md-12">
                        <label>Color de Empresa: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <ColorPicker
                            :color="dataEmpresa.color"
                            @successColor="successColor"
                        />
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-md-12">
                        <label>Nombre Empresa: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.nombre" placeholder="Nombre de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Nombre Propietario Empresa: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.propietario" placeholder="Nombre del Propietario" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Dirección: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.direccion" placeholder="Dirección de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Teléfono Fijo: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.telefono" placeholder="Teléfono de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Teléfono Celular: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.celular" placeholder="Teléfono Celular de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Correo Electrónico: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.correo" placeholder="Correo Electrónico de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Horario de Atención: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.horario" placeholder="Horario de Atención de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-12">
                        <label>Ciudad: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input size="large" v-model="dataEmpresa.ciudad" placeholder="Ciudad de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>
    
                <br>

                <div class="row">
                    <FileImage
                        titulo="Agregar Logo Principal 450 x 450"
                        @uploadImage="successLogoPrincipal"
                    />
                </div>

                <div class="row">
                    <FileImage
                        titulo="Agregar Logo Horizontal 460 x 98"
                        @uploadImage="successLogoHorizontal"
                    />
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Link Pagina Web: <span style="font-size:11px; font-weight:700; color:#05729b;"> Opcional *</span></label>
                        <a-input size="large" v-model="dataEmpresa.link_web" placeholder="Link Pagina Web de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Link Facebook: <span style="font-size:11px; font-weight:700; color:#05729b;"> Opcional *</span></label>
                        <a-input size="large" v-model="dataEmpresa.link_facebook" placeholder="Link Facebook de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Link Instagram: <span style="font-size:11px; font-weight:700; color:#05729b;"> Opcional *</span></label>
                        <a-input size="large" v-model="dataEmpresa.link_instagram" placeholder="Link Instagram de la Empresa" onfocus="myFunction(this)"/>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Slogan de la Empresa: <span style="font-size:11px; font-weight:700; color:#05729b;"> Opcional *</span></label>
                        <wysiwyg v-model="dataEmpresa.slogan"></wysiwyg>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <label>Descripcion de la Empresa: <span style="font-size:11px; font-weight:700; color:#05729b;"> Opcional *</span></label>
                        <wysiwyg v-model="dataEmpresa.descripcion"></wysiwyg>
                        <br><br>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalEmpresa= false">
                    CANCELAR
                </a-button>
                <a-button 
                    v-if="tipo === 'editar'" 
                    @click.once="editarEmpresa()" 
                    :key="buttonKeyActualizarEmpresa" 
                    type="primary" 
                    :loading="loading"
                    size="large"
                    >
                    ACTUALIZAR EMPRESA
                </a-button>
            </template>
        </a-modal>

        <!-- NUEVO FORMATO SISTEMA -->
        <a-modal
            v-model="showModalFormatearSistema"
            title="Dar nuevo formato al Sistema"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Ingrese su Contraseña: <span style="font-size:11px; font-weight:700; color:#05729b;"> Obligatorio *</span></label>
                        <a-input-password size="large" v-model="consulta.password" placeholder="Ingresar Contraseña"  onfocus="myFunction(this)"/>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="showModalFormatearSistema = false">
                    CANCELAR
                </a-button>
                <a-button @click.once="guardarFormatoSistema()" :key="buttonKeyFormatoSistema" type="primary" :loading="loading">
                    REALIZAR EL FORMATO
                </a-button>
            </template>
        </a-modal>

		<div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    
    </div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import router from '@/config/router'
  import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
  import moment from 'moment'
  import 'moment/locale/es'
  import * as utils from '@/utils/utils'
  import { EuropioCode } from '@/utils/europiocode'
  import axios from '@/config/axios/axios'
  import Swal from 'sweetalert2'
  import VueFileAgent from 'vue-file-agent'
  import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
  import FileImage from '../../components/FileImage.vue'
  import wysiwyg from 'vue-wysiwyg'
  import ColorPicker from '../../components/ColorPicker.vue'

  Vue.use(VueFileAgent)
  Vue.use(wysiwyg, {
        hideModules: { 
            image: true,
            code: true,
            removeFormat: true 
        }
    })

  export default {

    components: {
        FileImage,
        ColorPicker
    },
	
    data () {
        return {
                imagenMiniatura: '',
                src: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/icono.png' : '/static/assets/images/icono.png',
                srcHorizontal: (process.env.NODE_ENV === 'production') ? '/frontend/static/assets/images/logo.png' : '/static/assets/images/logo.png',
                modalEmpresa: false,
                buttonKeyGuardarEmpresa: 10,
                buttonKeyActualizarEmpresa: 20,
                buttonKeyFormatoSistema: 30,
                tipo: '',
                consulta: {
                    password: ''
                },
                loading: false,
                dataEmpresa: {},
                spinnerloading: false,
                showModalFormatearSistema: false
            }
        },

        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('empresa', ['empresa'])
        },

        methods: {
            ...mapActions('empresa', ['showEmpresa', 'updateEmpresa']),
            ...mapActions('backup', ['formatSistema']),
            ...mapActions('auth', ['checkUserMain']),

            openModalEmpresa (data, tipo) {
                this.dataEmpresa = { ...this.empresa }
                this.tipo = tipo
                this.modalEmpresa = true
            },

            successLogoPrincipal (value) {
                this.dataEmpresa.file = value
            },

            successLogoHorizontal (value) {
                this.dataEmpresa.file_horizontal = value
            },

            successColor (value) {
                this.dataEmpresa.color = value
            },

            editarEmpresa () {
                this.spinnerloading = true
				this.updateEmpresa(this.dataEmpresa)
                .then(response => {
                    if (response.status === 201) {
                        this.showEmpresa()
                        this.checkUserMain()
					    utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                        this.spinnerloading = false
                        this.modalEmpresa = false
                    }
				})
                .catch(error => {
					this.loading = false
					utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
				})
                this.buttonKeyActualizarEmpresa++
            },

            abrirFormatearSistema () {
				this.showModalFormatearSistema = true
			},

            guardarFormatoSistema () {
				Swal.fire({
                    title: 'Desea Realizar Formato al Sistema ?',
                    text: 'Esta opción reiniciará y eliminará todos los datos de uso del sistema de manera completa para empezar de nuevo en la información.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
                        this.formatSistema(this.consulta)
                        .then(response => {
                            if (response.status === 201) {
                                utils.openNotificationWithIcon('success', 'Exito', response.data.message, 'topRight')
                                this.spinnerloading = false
                                this.showModalFormatearSistema = false
                            }
                        }).catch(error => {
                            this.loading = false
                            utils.openNotificationWithIcon('error', 'Error al enviar la Informacion', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                    }
                })
			}
        }
  }
</script>

<style scoped>
    @import "~vue-wysiwyg/dist/vueWysiwyg.css";
    .spinner {
        position: fixed;
        top: 0; 
        right: 0; 
        bottom: 0; 
        left: 0; 
        background: rgba(0, 0, 0, 0.5); 
        z-index:1055;
    }

    .profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.profile-image, .profile-logo {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
}

.profile-img, .profile-logo-img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.profile-username {
  font-size: 24px;
  font-weight: bold;
  margin: 10px 0;
}

.profile-owner {
  font-size: 16px;
  color: #666;
}

.profile-info {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.profile-info li {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}

.info-value {
  color: #333;
}

@media (max-width: 600px) {
  .profile-img, .profile-logo-img {
    width: 100px;
    height: 100px;
  }

  .profile-username {
    font-size: 20px;
  }

  .profile-owner {
    font-size: 14px;
  }

  .profile-info li {
    font-size: 14px;
  }
}

</style>